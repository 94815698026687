














































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Forms, Alerts } from '@constructors';
import {
  CheckBox,
  RichCheckBoxList,
  RichText,
  FormUpload,
  Popup,
  FormSelect,
  FormText,
  FormContainer,
} from '@components';
import { NurseryModule } from '@store';
import { INurseryTag, INursery, InurseryArgs, INurseryUniqueInput } from '@models';
import { latitude, longitude, phoneValidator } from '@validators';
import { requiredIf, numeric, maxLength } from 'vuelidate/lib/validators';

@Component({
  components: {
    RichCheckBoxList,
    RichText,
    FormUpload,
    FormSelect,
    FormText,
    FormContainer,
    CheckBox,
  },
  validations: {
    localisationForm: { fieldsValues: { lat: { latitude }, lon: { longitude } } },
    phoneForm: { fieldsValues: { phone: { phoneValidator } } },
  },
})
export default class CrecheDescription extends Vue {
  get nursery() {
    return NurseryModule.state.oneNursery;
  }

  $refs: {
    imagePopup: Popup;
    description: RichText;
  };

  public selectDescription = null;
  setDescriptionValue(value: string) {
    this.selectDescription.value = value;
    this.$refs.description.setValue(this.descriptionForm.fieldsValues[value]);
  }

  public descriptionForm: Forms.Form<any> = null;

  public localisationForm = new Forms.Form({
    fields: {
      lat: new Forms.TextForm<any>({
        value: this.nursery.lat,
        editMode: !!this.nursery.lat,
        label: 'Latitude',
        valueType: 'number',
        placeholder: 'Latitude',
      }),
      lon: new Forms.TextForm({
        value: this.nursery.lon,
        editMode: !!this.nursery.lon,
        label: 'Longitude',
        valueType: 'number',
      }),
    },
    validations: {},
  });

  public phoneForm = new Forms.Form({
    fields: {
      phone: new Forms.TextForm({
        type: 'tel',
        label: 'Je renseigne le numéro de téléphone :',
        value: this.nursery.reservationPhone,
        width: '25%',
        placeholder: 'Numéro de téléphone',
      }),
    },
    validations: { phone: { phone: phoneValidator } },
  });

  public summerCampForm = new Forms.Form({
    fields: {
      dates: new Forms.Calendar<Forms.ICalendarPeriodType>({
        label: 'Dates',
        calendarType: 'period',
        width: '300px',
        value: this.isSummerCamp
          ? { start: this.nursery.summerCampStartDate, end: this.nursery.summerCampEndDate }
          : null,
      }),
    },
  });

  get summerCampFormValidations(): Forms.ValidationTree<
    Forms.ExtractTree<typeof CrecheDescription.prototype.summerCampForm>
  > {
    return {
      dates: {
        required: requiredIf(() => this.descriptionForm.fieldsValues.tags.includes('SUMMER_CAMP')),
      },
    };
  }

  async update24hReservationValue() {
    await NurseryModule.actions.updateNursery({
      id: this.nursery.id,
      data: {
        oneDayBeforeReservation: this.nursery.oneDayBeforeReservation,
      },
    });
  }

  async updateOnlyPhoneReservationEnableValue() {
    await NurseryModule.actions.updateNursery({
      id: this.nursery.id,
      data: {
        onlyPhoneReservationEnable: this.nursery.onlyPhoneReservationEnable,
      },
    });
  }

  get isDisabled() {
    return (
      (this.$v.localisationForm.$invalid && this.$v.localisationForm.$dirty) ||
      !this.phoneForm.isFormValid
    );
  }

  get updateDisabled() {
    return !this.descriptionForm.isFormModified;
  }

  get isInternational() {
    return this.nursery.tags.includes('INTERNATIONNAL');
  }
  get isGraine() {
    return this.nursery.tags.includes('ATELIER');
  }
  get isBio() {
    return this.nursery.tags.includes('BIO_ALIMENTATION');
  }
  get isSummerCamp() {
    return this.nursery.tags.includes('SUMMER_CAMP');
  }

  async handleUpdateNursery() {
    let { dates } = this.summerCampForm.getModifiedData();
    let { tags, ...descriptionData } = this.descriptionForm.getValues();
    let { phone } = this.phoneForm.getValues();
    if (tags == null) tags = [];
    const isSummerCamp: boolean = tags.includes('SUMMER_CAMP');
    let summerCampStartDate = null;
    let summerCampEndDate = null;
    if (isSummerCamp && dates.start && dates.end) {
      summerCampStartDate = new Date(dates.start);
      summerCampStartDate.setHours(12);
      summerCampEndDate = new Date(dates.end);
      summerCampEndDate.setHours(12);
    }

    await NurseryModule.actions.updateNursery({
      id: this.nursery.id,
      data: {
        ...this.localisationForm.getValues(),
        ...descriptionData,
        tags,
        summerCampStartDate: summerCampStartDate,
        summerCampEndDate: summerCampEndDate,
        reservationPhone: phone,
      },
    });
    this.descriptionForm.reset();
    this.renderForm();
  }

  async handleImageImport() {
    await NurseryModule.actions.updateNursery({
      id: this.nursery.id,
      data: {
        picture: this.imageImport.value,
      },
    });
    this.imageImport.reset();
    this.$refs.imagePopup.closePopup();
  }
  async deletePicture(id: string) {
    var alert = new Alerts.ConfirmAlert({
      title: `Supprimer l'image?`,
      actions: [
        new Alerts.ConfirmAction({
          text: 'Supprimer',
          handler: () => NurseryModule.actions.deleteNurseryPicture(id),
        }),
      ],
    });
  }

  public imageImport = new Forms.UploadForm<File>({
    placeholder: 'Importer une image',
    noMargin: true,
    noBorder: true,
  }).extract();

  handleTagUpdate(value: INurseryTag[]) {
    NurseryModule.updateState({
      oneNursery: {
        ...NurseryModule.state.oneNursery,
        tags: value,
      },
    });
    this.descriptionForm.fieldsValues.tags = value;
    this.renderForm();
    this.$nextTick(() => {
      this.$refs.description.resetValue();
    });
  }

  handleLatitude(value: number) {
    NurseryModule.updateState({
      oneNursery: {
        ...NurseryModule.state.oneNursery,
        lat: value,
      },
    });
    this.localisationForm.fieldsValues.lat = value;
  }

  handleLongitude(value: number) {
    NurseryModule.updateState({
      oneNursery: {
        ...NurseryModule.state.oneNursery,
        lon: value,
      },
    });
    this.localisationForm.fieldsValues.lon = value;
  }

  renderForm() {
    this.descriptionForm = new Forms.Form({
      fields: {
        description: new Forms.RichText({
          value: this.nursery.description,
        }),

        nurseryPlus: new Forms.RichText({
          value: this.nursery.nurseryPlus,
        }),
        information: new Forms.RichText({
          value: this.nursery.information,
        }),
        pedagogy: new Forms.RichText({
          value: this.nursery.pedagogy,
        }),
        ...(this.isInternational && {
          tagInternationnal: new Forms.RichText({
            value: this.nursery.tagInternationnal,
          }),
        }),
        ...(this.isGraine && {
          tagGraine: new Forms.RichText({
            value: this.nursery.tagGraine,
          }),
        }),
        ...(this.isBio && {
          tagBio: new Forms.RichText({
            value: this.nursery.tagBio,
          }),
        }),
        tags: new Forms.RichCheckBoxList<INurseryTag[]>({
          value: this.nursery.tags,
          checkboxs: [
            {
              value: 'BIO_ALIMENTATION',
              text: 'Alimentation 50% Bio',
              icon: 'icons/crechelib/leaf.svg',
            },
            {
              value: 'INTERNATIONNAL',
              text: 'Crèche internationale',
              icon: 'icons/crechelib/internationale.svg',
            },
            {
              value: 'GENERATION_DURABLE',
              text: 'Génération durable',
              icon: 'icons/crechelib/butterfly.svg',
            },
            {
              value: 'ATELIER',
              text: 'Atelier',
              icon: 'icons/crechelib/lightbulb.svg',
            },
            {
              value: 'SUMMER_CAMP',
              text: 'Summer Camp',
              icon: 'icons/crechelib/summercamp.svg',
            },
          ],
        }),
      },
      editMode: true,
    });
    this.selectDescription = new Forms.Select({
      value: 'description',
      placeholder: 'Sélectionner une description',
      width: '300px',
      options: [
        { value: 'description', text: 'Introduction à la crèche' },
        { value: 'nurseryPlus', text: 'Les + de la crèche' },
        {
          value: 'information',
          text: 'Descriptif de la structure',
        },
        {
          value: 'pedagogy',
          text: 'Génération durable',
        },
        ...(this.nursery.tags.includes('INTERNATIONNAL')
          ? [
              {
                value: 'tagInternationnal',
                text: 'Crèche Internationale',
              },
            ]
          : []),
        ...(this.nursery.tags.includes('ATELIER')
          ? [
              {
                value: 'tagGraine',
                text: 'Ateliers',
              },
            ]
          : []),
        ...(this.nursery.tags.includes('BIO_ALIMENTATION')
          ? [
              {
                value: 'tagBio',
                text: 'Crèches Bio',
              },
            ]
          : []),
      ],
    }).extract();
  }

  created() {
    this.renderForm();
  }

  mounted() {
    this.descriptionForm.$v.description;
  }
}
